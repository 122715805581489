import HomeIcon from "@/public/assets/icons/web/home.svg";
import ProfileIcon from "@/public/assets/icons/web/profile.svg";
import SettingsIcon from "@/public/assets/icons/web/settings.svg";
import NotificationIcon from "@/public/assets/icons/web/notifications.svg";
import SearchIcon from "@/public/assets/icons/web/search.svg";
import PremiumIcon from "@/public/assets/icons/drawer/premiumIcon.svg";
import SupportIcon from "@/public/assets/icons/supportIcon.svg";
import { PostFlagType } from "@/gql/graphql";

import { MenuItem } from "./types";
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID;

export const NavbarData = [
  {
    icon: HomeIcon,
    title: "Home",
    link: "/home/myschool",
  },
  {
    icon: SearchIcon,
    title: "Search",
    link: "/search",
  },
  {
    icon: NotificationIcon,
    title: "Notifications",
    link: "/notification",
  },

  {
    icon: PremiumIcon,
    title: "Premium",
    link: "/premium",
  },
  {
    icon: ProfileIcon,
    title: "Profile",
    link: "/profile/",
  },

  {
    icon: SettingsIcon,
    title: "Settings",
    link: "/settings/account-information",
  },
  {
    icon: SupportIcon,
    title: "Support",
    link: "/support",
  },
];

export const menuItems: MenuItem[] = [
  {
    id: 1,
    name: "Home",
    icon: "/assets/icons/web/home.svg",
    link: "/home/myschool",
  },
  {
    id: 2,
    name: "Search",
    icon: "/assets/icons/web/search.svg",
    link: "/search",
  },
  {
    id: 3,
    name: "Premium",
    icon: "/assets/icons/drawer/premiumIcon.svg",
    link: "/premium",
  },
  {
    id: 4,
    name: "Notifications",
    icon: "/assets/icons/web/notifications.svg",
    link: "/notification",
  },
  // {
  //   id: 5,
  //   name: "Request",
  //   icon: "/assets/icons/drawer/requestIcon.svg",
  //   link: "/requests",
  // },
  {
    id: 6,
    name: "Profile",
    icon: "/assets/icons/web/profile.svg",
    link: "/profile/overview",
  },
  {
    id: 7,
    name: "Settings",
    icon: "/assets/icons/drawer/settingIcon.svg",
    link: "/settings/account",
  },
  // {
  //   id: 8,
  //   name: "Organizations",
  //   icon: "/assets/icons/web/organizations.svg",
  //   link: "/organization/overview",
  // },
];

export const ReviewPostsData = [
  {
    schoolLogo: "https://picsum.photos/200",
    postBy: "Addy",
    universityName: "AAMU",
    tags: [
      "@Alpha Chi Rho",
      "@Alpha Gamma Delta",
      "@Acacia",
      "@Alpha Gamma Delta",
    ],
    tiers: [
      {
        tierName: "Awesome",
        tierOrganizations: [
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Acacia",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Alpha Chi Ro",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Alpha Alpha Alpha",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "@Alpha Gamma Delta",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "@Alpha Gamma phi",
          },
        ],
      },
      {
        tierName: "Cool",
        tierOrganizations: [
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Acacia",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Alpha Chi Ro",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Alpha Alpha Alpha",
          },
        ],
      },
      {
        tierName: "Shhaten",
        tierOrganizations: [
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Acacia",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Alpha Chi Ro",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Alpha Alpha Alpha",
          },
        ],
      },
      {
        tierName: "MummifiedM",
        tierOrganizations: [
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Acacia",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Alpha Chi Ro",
          },
          {
            orgnizationLogo: "https://picsum.photos/200",
            organizationName: "Alpha Alpha Alpha",
          },
        ],
      },
    ],
    views: 30000,
    upVotes: 10,
    downVotes: 3,
    comments: 300,
    sharedPosts: 2000,
    savedPosts: 1500,
    type: "REVIEW",
    username: "wajahat",
    id: "w10001",
  },
];

export const TextPostsData = [
  {
    schoolLogo: "/assets/images/svgimages/schoolLogo.svg",
    universityName: "XYZ University",
    tags: ["@DEF", "@GHI", "@JKL", "@MNO"],
    postBy: "Emma",
    cardComments:
      "The university's new sustainability initiative focuses on reducing plastic waste on campus. This effort includes installing water refill stations and providing reusable containers to students. The initiative aims to promote environmental consciousness among students and staff.",
    cardBy: "JohnDoe",
    views: 30000,
    upVotes: 10,
    downVotes: 3,
    comments: 300,
    sharedPosts: 2000,
    savedPosts: 1500,
    type: "POST",
    username: "suhaib",
    id: "s10001",
  },
];

export const PollPostsData = [
  {
    username: "johndoe",
    id: "jd10001",
    schoolLogo: "/assets/images/svgimages/schoolLogo.svg",
    universityName: "XYZ University",
    tags: ["@ABC", "@DEF", "@GHI", "@JKL", "@MNO", "@PQR", "@STU"],
    postBy: "Emma",
    pollComment:
      "Should the University Implement a Mandatory Recycling Program?",
    time: "3 hr 10 min",
    totalVotes: 150000,
    agree: 200,
    disagree: 50,
    cardBy: "JohnDoe",
    views: 30000,
    upVotes: 10,
    downVotes: 3,
    comments: 300,
    sharedPosts: 2000,
    savedPosts: 1500,
    type: "POLL",
  },
];

export const PostsData = [
  ...TextPostsData,
  ...PollPostsData,
  ...ReviewPostsData,
];

export const FeedFilterData = {
  homepage: [
    {
      label: "My School",
      pushto: "myschool",
    },
    {
      label: "For You",
      pushto: "for-you",
    },
    { label: "Following", pushto: "following" },
  ],
  profilepage: [
    { label: "Overview", pushto: "overview" },
    {
      label: "Posts",
      pushto: "myposts",
    },
    {
      label: "Polls",
      pushto: "mypolls",
    },
    { label: "Rankings", pushto: "myrankings" },
    { label: "Comments", pushto: "mycomments" },
    { label: "Bookmarks", pushto: "saved-posts" },
  ],

  organizationpage: [
    {
      label: "Overview",
      pushto: "overview",
    },
    {
      label: "Posts",
      pushto: "posts",
    },
    { label: "Reviews", pushto: "reviews" },
  ],
};

export const ProfileData = [
  {
    MobileNumber: 1705460871,
    Mail: "xyz@gmail.com",
    Username: "Linda",
    UniversityName: "Alabama State University",
  },
];

export const NotificationData = [
  {
    type: "post",
    viewedBy: "23k+",
    notification:
      "Louis has mentioned about Alabama State University in a post",
    time: "just now",
    logo: "",
    isRead: true,
  },
  {
    type: "poll",
    viewedBy: "",
    notification: "Daven has stated a new poll",
    time: "10 hrs ago",
    Logo: "",
    isRead: true,
  },
  {
    type: "login",
    viewedBy: "",
    notification:
      "There was a login to your account from a new device on May 6, 2024",
    time: "20 hrs ago",
    logo: "",
    isRead: false,
  },
  {
    type: "tag",
    viewedBy: "",
    notification: "Louis has mentioned you in a post",
    time: "20 hrs ago",
    logo: "",
    isRead: true,
  },
  {
    type: "post",
    viewedBy: "10k+",
    notification: "Emily has shared a new blog post on web development",
    time: "2 days ago",
    logo: "/assets/images/svgimages/postLogo.svg",
    isRead: false,
  },
  {
    type: "poll",
    viewedBy: "5k+",
    notification:
      "John has created a new poll about the best programming language",
    time: "3 days ago",
    Logo: "/assets/images/svgimages/pollLogo.svg",
    isRead: false,
  },
  {
    type: "login",
    viewedBy: "",
    notification:
      "Your account was accessed from a new location on May 10, 2024",
    time: "4 days ago",
    logo: "/assets/images/svgimages/loginLogo.svg",
    isRead: false,
  },
  {
    type: "tag",
    viewedBy: "2k+",
    notification: "Sarah has mentioned you in her latest blog post",
    time: "5 days ago",
    logo: "/assets/images/svgimages/tagLogo.svg",
    isRead: false,
  },
  {
    type: "post",
    viewedBy: "15k+",
    notification: "David has shared a new tutorial on React hooks",
    time: "1 week ago",
    logo: "/assets/images/svgimages/postLogo.svg",
    isRead: true,
  },
  {
    type: "poll",
    viewedBy: "8k+",
    notification: "Jessica has created a new poll about the best code editor",
    time: "2 weeks ago",
    Logo: "/assets/images/svgimages/pollLogo.svg",
    isRead: true,
  },
];

export const characterLimit = 450;

export const OrganizationReviewData = [
  {
    ratingNumber: 3,
    ratingLabel: "Top Tier",
    ratingDescription:
      "these guys never stop saying awesome-sauce. I went to a party and every brother I talked to mentioned awesome-sauce at least once",
    reputation: [3, 4, 5, 3, 3, 3],
    nickname: "goob",
    votes: {
      upvote: 192,
      downvote: 202,
    },
  },
];

export const SearchData = {
  schools: [
    {
      logo: "https://picsum.photos/id/200/200",
      name: "Abraham Baldwin Agricultural College",
      followers: 100000,
    },
    {
      logo: "https://picsum.photos/id/201/200",
      name: "Georgia Institute of Technology",
      followers: 520000,
    },
    {
      logo: "https://picsum.photos/id/202/200",
      name: "Emory University",
      followers: 380000,
    },
    {
      logo: "https://picsum.photos/id/203/200",
      name: "Savannah College of Art and Design",
      followers: 210000,
    },
    {
      logo: "https://picsum.photos/id/204/200",
      name: "University of Georgia",
      followers: 430000,
    },
    {
      logo: "https://picsum.photos/id/205/200",
      name: "Georgia State University",
      followers: 350000,
    },
    {
      logo: "https://picsum.photos/id/206/200",
      name: "Mercer University",
      followers: 90000,
    },
    {
      logo: "https://picsum.photos/id/207/200",
      name: "Kennesaw State University",
      followers: 160000,
    },
    {
      logo: "https://picsum.photos/id/208/200",
      name: "Spelman College",
      followers: 140000,
    },
    {
      logo: "https://picsum.photos/id/209/200",
      name: "Clark Atlanta University",
      followers: 120000,
    },
    {
      logo: "https://picsum.photos/id/210/200",
      name: "Morehouse College",
      followers: 130000,
    },
    {
      logo: "https://picsum.photos/id/211/200",
      name: "Agnes Scott College",
      followers: 50000,
    },
    {
      logo: "https://picsum.photos/id/212/200",
      name: "Berry College",
      followers: 60000,
    },
    {
      logo: "https://picsum.photos/id/213/200",
      name: "Columbus State University",
      followers: 70000,
    },
  ],
  fraternities: [
    {
      logo: "https://picsum.photos/id/204/200",
      name: "Alpha Tau Omega",
      followers: 210000,
    },
    {
      logo: "https://picsum.photos/id/205/200",
      name: "Kappa Sigma",
      followers: 200000,
    },
    {
      logo: "https://picsum.photos/id/206/200",
      name: "Sigma Phi Epsilon",
      followers: 310000,
    },
    {
      logo: "https://picsum.photos/id/207/200",
      name: "Phi Delta Theta",
      followers: 110000,
    },
    {
      logo: "https://picsum.photos/id/208/200",
      name: "Sigma Alpha Epsilon",
      followers: 320000,
    },
    {
      logo: "https://picsum.photos/id/209/200",
      name: "Pi Kappa Alpha",
      followers: 290000,
    },
    {
      logo: "https://picsum.photos/id/210/200",
      name: "Delta Tau Delta",
      followers: 150000,
    },
    {
      logo: "https://picsum.photos/id/211/200",
      name: "Beta Theta Pi",
      followers: 170000,
    },
    {
      logo: "https://picsum.photos/id/212/200",
      name: "Lambda Chi Alpha",
      followers: 140000,
    },
    {
      logo: "https://picsum.photos/id/213/200",
      name: "Phi Gamma Delta",
      followers: 180000,
    },
    {
      logo: "https://picsum.photos/id/214/200",
      name: "Sigma Nu",
      followers: 220000,
    },
    {
      logo: "https://picsum.photos/id/215/200",
      name: "Theta Chi",
      followers: 130000,
    },
    {
      logo: "https://picsum.photos/id/216/200",
      name: "Alpha Sigma Phi",
      followers: 120000,
    },
    {
      logo: "https://picsum.photos/id/217/200",
      name: "Tau Kappa Epsilon",
      followers: 160000,
    },
  ],
  sororities: [
    {
      logo: "https://picsum.photos/id/208/200",
      name: "Alpha Chi Omega",
      followers: 210000,
    },
    {
      logo: "https://picsum.photos/id/209/200",
      name: "Chi Omega",
      followers: 200000,
    },
    {
      logo: "https://picsum.photos/id/210/200",
      name: "Kappa Delta",
      followers: 310000,
    },
    {
      logo: "https://picsum.photos/id/211/200",
      name: "Delta Gamma",
      followers: 110000,
    },
    {
      logo: "https://picsum.photos/id/212/200",
      name: "Alpha Delta Pi",
      followers: 320000,
    },
    {
      logo: "https://picsum.photos/id/213/200",
      name: "Delta Delta Delta",
      followers: 290000,
    },
    {
      logo: "https://picsum.photos/id/214/200",
      name: "Alpha Gamma Delta",
      followers: 150000,
    },
    {
      logo: "https://picsum.photos/id/215/200",
      name: "Kappa Kappa Gamma",
      followers: 170000,
    },
    {
      logo: "https://picsum.photos/id/216/200",
      name: "Pi Beta Phi",
      followers: 140000,
    },
    {
      logo: "https://picsum.photos/id/217/200",
      name: "Zeta Tau Alpha",
      followers: 180000,
    },
    {
      logo: "https://picsum.photos/id/218/200",
      name: "Phi Mu",
      followers: 220000,
    },
    {
      logo: "https://picsum.photos/id/219/200",
      name: "Alpha Phi",
      followers: 130000,
    },
    {
      logo: "https://picsum.photos/id/220/200",
      name: "Gamma Phi Beta",
      followers: 120000,
    },
    {
      logo: "https://picsum.photos/id/221/200",
      name: "Delta Zeta",
      followers: 160000,
    },
  ],
};

export const keywords = [
  "XYZUniversity",
  "ABCCollege",
  "LMNInstitute",
  "PQRUniversity",
  "STUCollege",
  "sustainabilityinitiative",
  "reducingplasticwaste",
  "waterrefillstations",
  "reusablecontainers",
  "environmentalconsciousness",
  "artexhibit",
  "campusgallery",
  "studentandlocalartists",
  "creativecommunity",
  "techfair",
  "innovativeprojects",
  "robotics",
  "AI",
  "greentechnology",
  "networkingandcollaboration",
  "renewableenergy",
  "solarpower",
  "windpower",
  "carbonemissions",
  "mentalhealthconference",
  "studentwellbeing",
  "copingstrategies",
  "mentalhealthawareness",
  "recyclingprogram",
  "remotelearning",
  "inpersonclasses",
  "freecollegetuition",
  "vegetarianoptions",
  "onlineeducation",
  "AbrahamBaldwinAgriculturalCollege",
  "GeorgiaInstituteofTechnology",
  "EmoryUniversity",
  "SavannahCollegeofArtandDesign",
  "UniversityofGeorgia",
  "GeorgiaStateUniversity",
  "MercerUniversity",
  "KennesawStateUniversity",
  "SpelmanCollege",
  "ClarkAtlantaUniversity",
  "MorehouseCollege",
  "AgnesScottCollege",
  "BerryCollege",
  "ColumbusStateUniversity",
  "AlphaTauOmega",
  "KappaSigma",
  "SigmaPhiEpsilon",
  "PhiDeltaTheta",
  "SigmaAlphaEpsilon",
  "PiKappaAlpha",
  "DeltaTauDelta",
  "BetaThetaPi",
  "LambdaChiAlpha",
  "PhiGammaDelta",
  "SigmaNu",
  "ThetaChi",
  "AlphaSigmaPhi",
  "TauKappaEpsilon",
  "AlphaChiOmega",
  "ChiOmega",
  "KappaDelta",
  "DeltaGamma",
  "AlphaDeltaPi",
  "DeltaDeltaDelta",
  "AlphaGammaDelta",
  "KappaKappaGamma",
  "PiBetaPhi",
  "ZetaTauAlpha",
  "PhiMu",
  "AlphaPhi",
  "GammaPhiBeta",
  "DeltaZeta",
];

export interface LabelValue {
  readonly value: string;
  readonly label: string;
}

export const colourOptions: readonly LabelValue[] = [
  { value: "ocean", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" },
];

export const Options = [
  // {
  //   text: "Not Interested",
  //   image: "",
  // },
  {
    text: "Delete Post",
    hoverImage: "/assets/images/svgimages/deletePostHoverIcon.svg",
    normalImage: "/assets/images/svgimages/deletePostIcon.svg",
    forGuestUser: false,
  },
  {
    text: "Report Post",
    hoverImage: "/assets/images/svgimages/reportPostHoverIcon.svg",
    normalImage: "/assets/images/svgimages/reportPostIcon.svg",
    forGuestUser: true,
  },
  {
    text: "Restore Post",
    hoverImage: "/assets/images/svgimages/restorePostHoverIcon.svg",
    normalImage: "/assets/images/svgimages/restorePostIcon.svg",
    forGuestUser: false,
  },
  {
    text: "Super Delete",
    hoverImage: "/assets/images/svgimages/deletePostHoverIcon.svg",
    normalImage: "/assets/images/svgimages/deletePostIcon.svg",
    forGuestUser: false,
  },
];

export const ProfileScreenCardsData = [
  {
    universityLogo: "/assets/images/svgimages/universityLogo.svg",
    postViewsCount: 678,
    universityName: "Alabama State University",
    mail: "abc@gmail.com",
    joiningDate: "May 2024",
    upvotesCount: 23,
    followedOrganizationCount: 50,
    totalCommentsCount: 6,
    totalDeletes: 3,
  },
];

export const RatingsDate = [
  {
    label: "Friendliness",
    rating: 100,
  },
  {
    label: "Popularity",
    rating: 92,
  },
  {
    label: "Brotherhood",
    rating: 68,
  },
  {
    label: "Classiness",
    rating: 100,
  },
  {
    label: "Social Life",
    rating: 54,
  },
  {
    label: "Involvement",
    rating: 80,
  },
];

export const filterBy = ["Newest", "Most Viewed", "Most Votes", "Least Votes"];
export const universities = [
  {
    label: "University 1",
    value: "label1",
    image: "/assets/images/pngimages/alabama.jpg",
  },
  {
    label: "University 2",
    value: "label2",
    image: "/assets/images/pngimages/alabama.jpg",
  },
  {
    label: "University 3",
    value: "label3",
    image: "/assets/images/pngimages/alabama.jpg",
  },
  {
    label: "University 4",
    value: "label4",
    image: "/assets/images/pngimages/alabama.jpg",
  },
];

export const tiers = [
  {
    label: "Top",
    value: "label1",
    image: "/assets/images/pngimages/alabama.jpg",
  },
  {
    label: "Upper",
    value: "label2",
    image: "/assets/images/pngimages/alabama.jpg",
  },
  {
    label: "Mid",
    value: "label3",
    image: "/assets/images/pngimages/alabama.jpg",
  },
  {
    label: "Bottom",
    value: "label4",
    image: "/assets/images/pngimages/alabama.jpg",
  },
];

export const Plans = [
  {
    planName: "Premium",
    deleteCount: 3,
    planId: "premium4.99",
    planPrice: 4.99,
  },
  {
    planName: "Premium Plus",
    deleteCount: 10,
    planId: "premiumPlus9.99",
    planPrice: 9.99,
  },
];

export const Issues = [
  "Hate",
  "Abuse & Harrassment",
  "Violent Speech",
  "Privacy",
  "Suicide or self-harm",
  "Sensitive or disturbing media",
  "Impersonation",
  "Violent & hateful entities",
  "Spam",
];

export const FLAG_POST_OPTIONS = [
  { label: "Inappropriate Content", value: PostFlagType.InappropriateContent },
  {
    label: "Nudity or Sexual Content",
    value: PostFlagType.NudityOrSexualContent,
  },
  { label: "Violence or Gore", value: PostFlagType.ViolenceOrGore },
  { label: "Harassment or Bullying", value: PostFlagType.HarassmentOrBullying },
  {
    label: "Hate Speech or Discrimination",
    value: PostFlagType.HateSpeechOrDiscrimination,
  },
  { label: "Spam", value: PostFlagType.Spam },
  { label: "Misinformation", value: PostFlagType.Misinformation },
  { label: "Fake News", value: PostFlagType.FakeNews },
  { label: "Other", value: PostFlagType.Other },
];

export const AllSocialMediaLogos = [
  {
    logo: "/assets/images/svgimages/facebookLogo.svg",
    routesTo: "https://m.facebook.com/61562627022045/",
  },
  {
    logo: "/assets/images/svgimages/twitterLogo.svg",
    routesTo: "https://www.x.com/therushlink",
  },
  {
    logo: "/assets/images/svgimages/instagramLogo.svg",
    routesTo: "https://www.instagram.com/therushlink",
  },
  {
    logo: "/assets/images/svgimages/linkedinLogo.svg",
    routesTo: "https://www.linkedin.com/company/rushlink/",
  },
  {
    logo: "/assets/images/svgimages/tiktokLogo.svg",
    routesTo: "https://tiktok.com/@therushlink?lang=en",
  },
  {
    logo: "/assets/images/svgimages/youTubeLogo.svg",
    routesTo: "https://www.youtube.com/@therushlink-yt",
  },
  {
    logo: "/assets/images/svgimages/threadsLogo.svg",
    routesTo: "https://www.threads.net/@therushlink?hl=en",
  },
];
